import React, { useMemo, useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import {
    AndroidModelInput,
    DevicePlatform,
    FrontPageQueryQuery,
    FrontPageQueryQueryVariables,
    LocalPriceFragment,
    PassengerType,
    TicketEndedReason,
    TicketRowFragment,
} from "../generated/gql/graphql";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RelativeTimeText, TimestampText } from "../widgets/date_time";
import { formatMoney } from "./NetsTransactionsListPage";
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    Collapse,
    Grid2,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { usePageTitle } from "../util/page_title";
import { formatCustomerId, formatPercentage, formatTicketId } from "../util/formatting";
import { formatBatteryUsage, formatLocationPermission } from "./ticket_v2/TicketAppTab";
import { DateTimeFormat, formatDuration } from "../util/date_time";
import { Masonry } from "@mui/lab";
import { isLearningEnvironment, isSuperUser } from "../util/active_user_context";
import { pullAll, range, reverse, sortBy, sumBy, without } from "lodash";
import { JsonDiagnosticButton } from "../widgets/diagnostics";
import { TicketLocalLegsTable } from "./ticket_v2/TicketInfoTab";
import { LOCAL_PRICE_FRAGMENT } from "./TicketPageV2";

import { useDebouncedValue } from "../util/react_hooks";
import { AndroidModelsFilter, DevicePlatformFilter, MultiSelectFilter } from "../widgets/data_table/filters";
import {
    CheckBoxIcon,
    CheckBoxOutlineBlankIcon,
    CreditCardOffIcon,
    DeveloperModeIcon,
    ErrorIcon,
    ExpandIcon,
    FilterListIcon,
    FirstPageIcon,
    GpsFixedIcon,
    KeyboardArrowDownIcon,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardArrowUpIcon,
    KeyOutlinedIcon,
    LastPageIcon,
    LockOpenOutlinedIcon,
    NavigationIcon,
    QuestionAnswerIcon,
    ScienceIcon,
    UnfoldLessIcon,
    VerifiedUserIcon,
    VisibilityIcon,
    WarningIcon,
} from "../widgets/icons";

export const TICKET_ROW_FRAGMENT = gql`
    fragment TicketRow on Ticket {
        id
        createdTime
        discount
        hasTelemetryData
        isSimulatedTicket
        refundApproved
        passengerTypeInfo {
            textDescription
            passengerType
            addonBicycle
            passengerTypeAddons
        }
        analysisConfidence

        endedReason
        endedTime

        startBatteryLevel
        endedBatteryLevel

        price
        basePrice
        invoicedTime
        processedTime
        amountDue
        amountPaid
        amountCaptured
        amountCredited

        statusPaymentText
        statusText
        verified
        legsDescription
        telemetryDataUploadConsentGiven
        telemetryDataUploadRequired
        automaticPurchase
        #  questionnaire { answered }

        customer {
            id
            phoneNumberFormatted
            name
            activeResearchGroup
        }

        deviceInfo {
            platform
            appVersionName
            appBuildCode
            androidManufacturer
            androidModel
            androidProduct
            androidSdkInt
            androidVersionRelease
            iosModel
            iosSystemVersion
            locationPermission
            pinchSurveysEnabled
            pinchAnalyticsEnabled
        }

        activeLocalPriceAnalysis {
            ...LocalPrice
        }

        complaints {
            id
        }
    }

    ${LOCAL_PRICE_FRAGMENT}
`;

const FRONT_PAGE_QUERY = gql`
    query FrontPageQuery(
        $query: String!
        $page: Int!
        $limit: Int!
        $endedReasons: [TicketEndedReason!]!
        $devicePlatform: DevicePlatform
        $appVersionName: [String!]!
        $iosSystemVersion: [String!]!
        $androidModel: [AndroidModelInput!]
        $androidSdkInt: [Int!]!
        $passengerType: [PassengerType!]!
    ) {
        recentTickets(
            query: $query
            page: $page
            limit: $limit
            endedReasons: $endedReasons
            devicePlatform: $devicePlatform
            appVersionName: $appVersionName
            iosSystemVersion: $iosSystemVersion
            androidModel: $androidModel
            androidSdkInt: $androidSdkInt
            passengerType: $passengerType
        ) {
            totalPages
            count
            filterOptions {
                endedReasons
                appVersionNames
                passengerTypes
                iosSystemVersions
                androidManufacturers {
                    manufacturer
                    models
                }
            }
            result {
                ...TicketRow
            }
        }
    }

    ${TICKET_ROW_FRAGMENT}
`;

type Ticket = TicketRowFragment;

const defaultRowsPerPage = 40;

function getUserDefaultRowsPerPage(): number {
    return parseInt(localStorage.getItem("rowsPerPage") || "0") || defaultRowsPerPage;
}

export function FrontPage() {
    usePageTitle("Reisefrihet portal");

    const visibleColumnsItemKey = "frontpage.visibleColumns";
    const [visibleColumns, setVisibleColumns] = useState<Set<ColumnId>>(
        readVisibleColumnsFromLocalStorage(visibleColumnsItemKey, defaultVisibleColumns),
    );

    const navigate = useNavigate();

    const params = useTicketListParams();
    console.log("params", params);
    const buildLink = useLinkBuilder();
    const debouncedParams = useDebouncedValue(params, 800);
    const { search, page, rowsPerPage } = params;
    const query = useQuery<FrontPageQueryQuery, FrontPageQueryQueryVariables>(FRONT_PAGE_QUERY, {
        variables: {
            query: debouncedParams.search,
            page: debouncedParams.page,
            limit: debouncedParams.rowsPerPage,
            endedReasons: [...debouncedParams.filter.endedReason],
            devicePlatform: debouncedParams.filter.devicePlatform,
            appVersionName: [...debouncedParams.filter.appVersionName],
            iosSystemVersion: [...debouncedParams.filter.iosSystemVersion],
            androidSdkInt: [...debouncedParams.filter.androidSdkInt],
            androidModel: [...debouncedParams.filter.androidModel.values().map(toAndroidModelInput)],
            passengerType: [...debouncedParams.filter.passengerType],
        },
    });
    const recentTickets = (query.data || query.previousData)?.recentTickets;

    return (
        <div style={{ width: "100%", height: "100%", overflowY: "hidden" }}>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: visibleColumns.size > 2 ? "2500px" : "1300px",
                    margin: "0 auto",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            visibility: query.loading ? "visible" : "hidden",
                        }}
                    >
                        <CircularProgress />
                    </div>

                    <div style={{ width: "400px", margin: "40px 20px" }}>
                        <TextField
                            autoFocus
                            fullWidth
                            label={"Søk etter billettnummer, app-ID, eller mobilnummer"}
                            variant={"filled"}
                            defaultValue={search}
                            onChange={(e) => navigate(buildLink({ search: e.target.value }), { replace: true })}
                        />
                    </div>

                    <div>
                        <ColumnsButton
                            value={visibleColumns}
                            onChange={(newValue) => {
                                setVisibleColumns(newValue);
                                writeVisibleColumsToLocalStorage(visibleColumnsItemKey, newValue);
                            }}
                        />
                        <FilterButton
                            data={recentTickets || null}
                            value={params.filter}
                            onChange={(value) =>
                                navigate(buildLink({ filter: { ...params.filter, ...value } }), { replace: true })
                            }
                        />
                    </div>
                </div>

                <div style={{ flex: "1", overflowY: "hidden" }}>
                    <Paper style={{ overflowY: "hidden", height: "100%" }}>
                        <TableContainer style={{ height: "100%" }}>
                            <TicketsTable
                                showCustomerInfo={true}
                                tickets={((recentTickets?.result as unknown) || []) as ReadonlyArray<TicketRowFragment>}
                                visibleColumns={visibleColumns}
                            />
                        </TableContainer>
                    </Paper>
                </div>
                <TablePagination
                    rowsPerPageOptions={[40, 100, 200]}
                    component="div"
                    count={recentTickets?.count || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onRowsPerPageChange={(event) => {
                        localStorage.setItem("rowsPerPage", event.target.value.toString());
                        navigate(buildLink({ page: 0, rowsPerPage: parseInt(event.target.value) }), { replace: true });
                    }}
                    onPageChange={(_, page) => navigate(buildLink({ page }), { replace: true })}
                    ActionsComponent={TablePaginationActions}
                />
            </div>
        </div>
    );
}

interface FrontPageLocationParams {
    readonly page: number;
    readonly rowsPerPage: number;
    readonly search: string;
    readonly filter: TicketListFilterValue;
}

function useTicketListParams(): FrontPageLocationParams {
    const location = useLocation();
    const locationQuery = new URLSearchParams(location.search);
    const page = parseInt(locationQuery.get("page") || "0", 10) || 0;
    const rowsPerPage = parseInt(locationQuery.get("rowsPerPage") || "0", 10) || getUserDefaultRowsPerPage();
    const search = locationQuery.get("search") || "";

    function parseFilterParam<T>(paramName: string, transform?: (value: string) => T): Set<T> {
        const value = locationQuery.get(paramName);
        if (!value) return new Set();
        if (transform !== undefined) {
            return new Set(value.split(",").map(transform));
        } else {
            return new Set(value.split(",")) as unknown as Set<T>;
        }
    }

    return {
        page,
        rowsPerPage,
        search,
        filter: {
            endedReason: parseFilterParam("endedReason"),
            devicePlatform: locationQuery.get("devicePlatform") as DevicePlatform | null,
            appVersionName: parseFilterParam("appVersionName"),
            iosSystemVersion: parseFilterParam("iosSystemVersion"),
            passengerType: parseFilterParam("passengerType"),
            androidSdkInt: parseFilterParam<number>("androidSdkInt", (v) => parseInt(v, 10)),
            androidModel: parseFilterParam("androidModel"),
        },
    };
}

function useLinkBuilder(): (changedParams: Partial<FrontPageLocationParams>) => string {
    const params = useTicketListParams();
    return (changedParams: Partial<FrontPageLocationParams>) => buildLink(changedParams, params);
}

function buildLink(changedParams: Partial<FrontPageLocationParams>, currentParams?: FrontPageLocationParams) {
    const params = { ...(currentParams || useTicketListParams()), ...changedParams };
    const queryParameters = [];

    if (params.page > 0) queryParameters.push("page=" + params.page);
    if (params.rowsPerPage !== defaultRowsPerPage) queryParameters.push("rowsPerPage=" + params.rowsPerPage);
    if (params.search) queryParameters.push("search=" + encodeURIComponent(params.search));

    if (params.filter.endedReason.size > 0) {
        queryParameters.push("endedReason=" + [...params.filter.endedReason].join(","));
    }
    if (params.filter.devicePlatform) {
        queryParameters.push("devicePlatform=" + params.filter.devicePlatform);
    }
    if (params.filter.appVersionName.size > 0) {
        queryParameters.push("appVersionName=" + [...params.filter.appVersionName.values()].join(","));
    }
    if (params.filter.iosSystemVersion.size > 0) {
        queryParameters.push("iosSystemVersion=" + [...params.filter.iosSystemVersion.values()].join(","));
    }
    if (params.filter.androidModel.size > 0) {
        queryParameters.push("androidModel=" + [...params.filter.androidModel.values()].join(","));
    }
    if (params.filter.androidSdkInt.size > 0) {
        queryParameters.push(
            "androidSdkInt=" + [...params.filter.androidSdkInt.values().map((v) => v.toString())].join(","),
        );
    }

    return "/" + (queryParameters.length ? "?" : "") + queryParameters.join("&");
}

function FooterRow(props: {
    showCustomerInfo: boolean;
    tickets: ReadonlyArray<Ticket>;
    visibleColumns: ReadonlySet<ColumnId>;
}) {
    const visibleColumnsObjects = columns.filter((column) => props.visibleColumns.has(column.key));

    return (
        <TableRow>
            <TableCell colSpan={2 + (props.showCustomerInfo ? 1 : 0)}>Sum</TableCell>
            {visibleColumnsObjects.map((column) => {
                return (
                    <TableCell key={column.key} align={column.align}>
                        {column.footerBuilder ? (
                            <Typography variant={"body2"}>{column.footerBuilder(props.tickets)}</Typography>
                        ) : null}
                    </TableCell>
                );
            })}
            <TableCell colSpan={4} />
        </TableRow>
    );
}

function TicketsTable({
    showCustomerInfo,
    showFooter,
    tickets,
    visibleColumns,
}: {
    showCustomerInfo: boolean;
    showFooter?: boolean;
    tickets: ReadonlyArray<Ticket>;
    visibleColumns: ReadonlySet<ColumnId>;
}) {
    const eventTarget = useMemo(() => new EventTarget(), []);
    const [expandedTicketIds, setExpandedTicketIds] = useState<ReadonlyArray<number>>([]);
    const allExpanded =
        pullAll(
            tickets.map((t) => t.id),
            expandedTicketIds,
        ).length === 0;

    return (
        <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            size={"small"}
            style={{ overflowY: "scroll" }}
        >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <IconButton
                            onClick={() => {
                                if (allExpanded) setExpandedTicketIds([]);
                                else setExpandedTicketIds(tickets.map((t) => t.id));
                            }}
                        >
                            {allExpanded ? <UnfoldLessIcon /> : <ExpandIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell>Billett-Id</TableCell>
                    {showCustomerInfo ? (
                        <>
                            <TableCell>App-Id</TableCell>
                            <TableCell>Mobilnummer</TableCell>
                        </>
                    ) : null}
                    {columns
                        .filter((column) => visibleColumns.has(column.key))
                        .map((column) => {
                            return (
                                <TableCell key={column.key} align={column.align}>
                                    <Typography variant={"caption"}> {column.category}</Typography>
                                    <br />
                                    {column.label}
                                </TableCell>
                            );
                        })}
                    <TableCell />
                </TableRow>

                {showFooter ? (
                    <FooterRow showCustomerInfo={showCustomerInfo} tickets={tickets} visibleColumns={visibleColumns} />
                ) : null}
            </TableHead>

            <TableBody>
                {tickets.map((t) => {
                    const ticket = t as TicketRowFragment;
                    return (
                        <TicketRow
                            key={ticket.id}
                            eventTarget={eventTarget}
                            expanded={expandedTicketIds.indexOf(ticket.id) !== -1}
                            setExpanded={(newValue) => {
                                if (newValue) setExpandedTicketIds([ticket.id, ...expandedTicketIds]);
                                else setExpandedTicketIds(without(expandedTicketIds, ticket.id));
                            }}
                            ticket={ticket as TicketRowFragment}
                            visibleColumns={visibleColumns}
                            showCustomerInfo={showCustomerInfo}
                        />
                    );
                })}
            </TableBody>
        </Table>
    );
}

type TicketRowProps = {
    showCustomerInfo: boolean;
    expanded: boolean;
    setExpanded: (newValue: boolean) => void;
    eventTarget: EventTarget;
    ticket: TicketRowFragment;
    visibleColumns: ReadonlySet<ColumnId>;
};

function TicketRow({ showCustomerInfo, expanded, setExpanded, ticket, visibleColumns }: TicketRowProps) {
    const visibleColumnsObjects = columns.filter((column) => visibleColumns.has(column.key));
    const columnCount = 2 + (showCustomerInfo ? 2 : 0) + visibleColumnsObjects.length + 1;

    return [
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <TableCell>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                    disabled={!ticket.activeLocalPriceAnalysis}
                >
                    {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell>
                <Link to={"/ticket/" + ticket.id}>{formatTicketId(ticket.id)}</Link>
            </TableCell>
            {showCustomerInfo ? (
                <>
                    <TableCell>
                        <Link to={"/customer/" + ticket.customer.id}>{formatCustomerId(ticket.customer.id)}</Link>
                    </TableCell>
                    <TableCell>
                        {(ticket.customer.phoneNumberFormatted || "") +
                            (ticket.customer.name ? " (" + ticket.customer.name + ")" : "")}
                    </TableCell>
                </>
            ) : null}
            {visibleColumnsObjects.map((column) => {
                return (
                    <TableCell key={column.key} align={column.align}>
                        {column.cellBuilder ? column.cellBuilder(ticket) : (ticket[column.key as keyof Ticket] as any)}
                    </TableCell>
                );
            })}
            <TableCell style={{ whiteSpace: "nowrap" }}>
                {buildFlagIconOrNull(
                    ticket.telemetryDataUploadRequired,
                    <KeyOutlinedIcon fontSize={"inherit"} />,
                    "Data-deling er påkrevd",
                ) ||
                    buildFlagIcon(
                        ticket.telemetryDataUploadConsentGiven,
                        <LockOpenOutlinedIcon fontSize={"inherit"} />,
                        "Har samtykket til data-deling",
                    )}
                {buildFlagIcon(ticket.hasTelemetryData, <GpsFixedIcon fontSize={"inherit"} />, "Har GPS-data")}
                {buildFlagIcon(
                    ticket.verified,
                    <VerifiedUserIcon fontSize={"inherit"} />,
                    "Pris/analyse har blitt manuelt godkjent",
                )}
                {buildFlagIcon(ticket.isSimulatedTicket, <ScienceIcon fontSize={"inherit"} />, "Simulert billett")}
                {buildFlagIcon(
                    ticket.complaints.length !== 0,
                    <ErrorIcon color={"error"} fontSize={"inherit"} />,
                    "Klage mottatt",
                )}
                {buildFlagIcon(ticket.refundApproved, <CreditCardOffIcon fontSize={"inherit"} />, "Pris korrigert")}
                {buildFlagIcon(
                    ticket.deviceInfo?.pinchSurveysEnabled || ticket.deviceInfo?.pinchAnalyticsEnabled,
                    <QuestionAnswerIcon fontSize={"inherit"} />,
                    "Pinch aktivert",
                )}
                {buildFlagIcon(ticket.automaticPurchase, <NavigationIcon fontSize={"inherit"} />, "Automatisk kjøpt")}
                {/*{buildFlagIcon(ticket.questionnaire?.answered, <PollIcon fontSize="small />, "Spørreundersøkelse besvart")}*/}
                <JsonDiagnosticButton name={"ticket"} value={ticket} />
            </TableCell>
        </TableRow>,
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columnCount}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div"></Typography>
                        {ticket.activeLocalPriceAnalysis != null ? (
                            <TicketLocalLegsTable
                                ticket={ticket}
                                localPrice={ticket.activeLocalPriceAnalysis as LocalPriceFragment}
                            />
                        ) : null}
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>,
    ];
}

function buildFlagIconOrNull(visible: boolean, icon: React.JSX.Element, title: React.ReactNode) {
    if (!visible) return null;
    return buildFlagIcon(visible, icon, title);
}

export function buildFlagIcon(visible: boolean | null | undefined, icon: React.JSX.Element, title: React.ReactNode) {
    return (
        <Tooltip
            title={title}
            style={{
                visibility: visible ? "visible" : "hidden",
                fontSize: "20px",
            }}
        >
            {icon}
        </Tooltip>
    );
}

function formatDeviceModel(device: TicketRowFragment["deviceInfo"]): string {
    if (!device) return "";

    switch (device.platform) {
        case DevicePlatform.Android:
            return device.androidManufacturer + " – " + device.androidModel;
        case DevicePlatform.Ios:
            return device.iosModel || "";
        default:
            return "";
    }
}

function formatDeviceOSVersion(device: Ticket["deviceInfo"]): string {
    if (!device) return "";

    switch (device.platform) {
        case DevicePlatform.Android:
            return `Android ${device.androidVersionRelease} (SDK ${device.androidSdkInt})`;
        case DevicePlatform.Ios:
            return "iOS " + device.iosSystemVersion;
        default:
            return "";
    }
}

interface ColumnDefinition {
    key: string;
    label: string;
    align?: "left" | "right";
    category: (typeof ColumnCategories)[number];
    cellBuilder?: (row: Ticket) => React.ReactNode;
    footerBuilder?: (rows: ReadonlyArray<Ticket>) => React.ReactNode;
    filterBuilder?: (props: FilterProps) => React.ReactNode;
    defaultVisible?: boolean;
    requiredVisible?: boolean;
    requiresSuperUser?: boolean;
    learningEnvironmentVisibility?: boolean;
}

const ColumnCategories = ["Billett", "Kunde", "App", "Mobil", "Prosessering", "Korttrekk (kr)"];

function buildAmountDue(amountDue: number) {
    if (amountDue == 0) return null;
    if (amountDue > 0) return formatMoney(amountDue);
    else
        return (
            <span>
                <WarningIcon color={"error"} />
                {"Overbelastet "}
                <br />
                {formatMoney(-amountDue)}
            </span>
        );
}

const columns: ReadonlyArray<ColumnDefinition> = [
    {
        key: "createdTime",
        label: "Kjøpt",
        category: "Billett",
        cellBuilder: (ticket) => <TimestampText value={ticket.createdTime} />,
        defaultVisible: true,
    },
    {
        key: "passengerType",
        label: "Type",
        category: "Billett",
        cellBuilder: (ticket) => ticket.passengerTypeInfo.textDescription,
        defaultVisible: !isLearningEnvironment(),
        filterBuilder: (props) => (
            <MultiSelectFilter<PassengerType>
                id={"filter_passengerType"}
                label={"Type"}
                options={props.data?.filterOptions?.passengerTypes || []}
                value={[...props.value.passengerType.values()]}
                onChange={(v) => props.onChange({ passengerType: new Set(v) })}
            />
        ),
    },
    {
        key: "legsDescription",
        label: "Reisebeskrivelse",
        category: "Billett",
        defaultVisible: isLearningEnvironment(),
        cellBuilder: (ticket) => ticket.legsDescription.split("\n").map((line) => <div>{line}</div>),
    },
    {
        key: "endedReason",
        label: "Avsluttingsgrunn",
        category: "Billett",
        filterBuilder: (props) => (
            <MultiSelectFilter<TicketEndedReason>
                id={"filter_ticket_ended_reason"}
                label={"Avsluttingsgrunn"}
                options={props.data?.filterOptions?.endedReasons || []}
                value={[...props.value.endedReason.values()]}
                onChange={(endedReasons) => props.onChange({ endedReason: new Set(endedReasons) })}
            />
        ),
    },
    {
        key: "endedTime",
        label: "Avsluttingstid",
        cellBuilder: (ticket) => <TimestampText format={DateTimeFormat.TIME_SHORT} value={ticket.endedTime} />,
        category: "Billett",
    },
    {
        key: "processedTime",
        label: "ticket.processedTime",
        cellBuilder: (ticket) => <RelativeTimeText value={ticket.processedTime} relativeTo={ticket.createdTime} />,
        category: "Prosessering",
    },
    {
        key: "invoicedTime",
        label: "ticket.invoicedTime",
        cellBuilder: (ticket) => <RelativeTimeText value={ticket.invoicedTime} relativeTo={ticket.createdTime} />,
        category: "Prosessering",
    },
    {
        key: "statusText",
        label: "Status",
        cellBuilder: (ticket) => ticket.statusText,
        category: "Billett",
        requiredVisible: true,
        learningEnvironmentVisibility: false,
    },
    {
        key: "statusPaymentText",
        label: "Betalt",
        cellBuilder: (ticket) => ticket.statusPaymentText,
        category: "Billett",
        requiredVisible: true,
        learningEnvironmentVisibility: false,
    },
    {
        key: "price",
        label: "Billettpris",
        cellBuilder: (ticket) => (ticket.invoicedTime ? formatMoney(ticket.price) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "price")),
        category: "Billett",
        align: "right",
        requiredVisible: true,
        learningEnvironmentVisibility: false,
    },
    {
        key: "amountCaptured",
        label: "Trukket",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountCaptured !== 0 ? formatMoney(ticket.amountCaptured) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "amountCaptured")),
        category: "Korttrekk (kr)",
        learningEnvironmentVisibility: false,
    },
    {
        key: "amountCredited",
        label: "Refundert",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountCredited !== 0 ? formatMoney(ticket.amountCredited) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "amountCredited")),
        category: "Korttrekk (kr)",
        learningEnvironmentVisibility: false,
    },
    {
        key: "amountPaid",
        label: "Betalt",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountPaid !== 0 ? formatMoney(ticket.amountPaid) : null),
        footerBuilder: (tickets) => formatMoney(sumBy(tickets, "amountPaid")),
        category: "Korttrekk (kr)",
        learningEnvironmentVisibility: false,
    },
    {
        key: "amountDue",
        label: "Utestående",
        align: "right",
        cellBuilder: (ticket) => (ticket.amountDue !== 0 ? buildAmountDue(ticket.amountDue) : null),
        footerBuilder: (tickets) => buildAmountDue(sumBy(tickets, "amountDue")),
        defaultVisible: true,
        category: "Korttrekk (kr)",
        learningEnvironmentVisibility: false,
    },
    {
        key: "duration",
        label: "Varighet",
        align: "right",
        cellBuilder: (ticket) => formatDuration(ticket.createdTime, ticket.endedTime),
        category: "Billett",
    },
    {
        key: "analysisConfidence",
        label: "Treffsikkerhet",
        align: "right",
        cellBuilder: (ticket) => (ticket.processedTime ? formatPercentage(ticket.analysisConfidence) : null),
        category: "Billett",
    },
    {
        key: "discount",
        label: "Rabatt",
        align: "right",
        cellBuilder: (ticket) => ticket.discount + " %",
        category: "Billett",
    },
    {
        key: "activeResearchGroup",
        label: "Gruppe",
        cellBuilder: (ticket) => ticket.customer.activeResearchGroup,
        category: "Kunde",
    },
    {
        key: "appVersionName",
        label: "Versjon",
        category: "App",
        cellBuilder: (ticket) => ticket.deviceInfo?.appVersionName + "+" + ticket.deviceInfo?.appBuildCode,
        filterBuilder: (props) => (
            <MultiSelectFilter<string>
                id={"filter_app_version_name"}
                label={"Versjon"}
                options={props.data?.filterOptions?.appVersionNames?.filter((v) => !v.startsWith("5")) || []}
                value={[...props.value.appVersionName.values()]}
                onChange={(v) => props.onChange({ appVersionName: new Set(v) })}
            />
        ),
    },
    {
        key: "devicePlatform",
        label: "OS/Versjon",
        category: "Mobil",
        cellBuilder: (ticket) => formatDeviceOSVersion(ticket.deviceInfo),
        filterBuilder: (props) => [
            <DevicePlatformFilter
                value={props.value.devicePlatform}
                onChange={(v) => props.onChange({ devicePlatform: v })}
            />,
            <MultiSelectFilter<string>
                id={"filter_android_sdk_int"}
                label={"Android SDK"}
                options={range(26, 37)
                    .reverse()
                    .map((e) => e.toString())}
                value={[...props.value.androidSdkInt.values().map((e) => e.toString())]}
                onChange={(v) =>
                    props.onChange({
                        androidSdkInt: new Set(v.map((v) => parseInt(v, 10))),
                        iosSystemVersion: new Set(),
                        devicePlatform:
                            props.value.devicePlatform !== DevicePlatform.Ios ? props.value.devicePlatform : null,
                    })
                }
            />,
            <MultiSelectFilter<string>
                id={"filter_iosSystemVersion"}
                label={"iOS versjon"}
                options={props.data?.filterOptions?.iosSystemVersions || []}
                value={[...props.value.iosSystemVersion]}
                onChange={(v) =>
                    props.onChange({
                        iosSystemVersion: new Set(v),
                        androidSdkInt: new Set(),
                        androidModel: new Set(),
                        devicePlatform:
                            props.value.devicePlatform !== DevicePlatform.Android ? props.value.devicePlatform : null,
                    })
                }
            />,
        ],
    },
    {
        key: "deviceModel",
        label: "Modell",
        category: "Mobil",
        cellBuilder: (ticket) => formatDeviceModel(ticket.deviceInfo),
        filterBuilder: (props) => (
            <AndroidModelsFilter
                options={props.data?.filterOptions?.androidManufacturers || []}
                value={props.value.androidModel}
                onChange={(androidModels) =>
                    props.onChange({
                        androidModel: androidModels,
                        iosSystemVersion: new Set(),
                        devicePlatform:
                            props.value.devicePlatform !== DevicePlatform.Ios ? props.value.devicePlatform : null,
                    })
                }
            />
        ),
    },
    {
        key: "deviceProduct",
        label: "Produktkode",
        category: "Mobil",
        cellBuilder: (ticket) => ticket.deviceInfo?.androidProduct,
        requiresSuperUser: true,
    },
    {
        key: "deviceLocationPermission",
        label: "Steds-tillatelse",
        category: "App",
        cellBuilder: (ticket) => formatLocationPermission(ticket.deviceInfo?.locationPermission),
    },
    {
        key: "batteryUsage",
        label: "Batteri-forbruk",
        category: "App",
        align: "right",
        cellBuilder: (ticket) => formatBatteryUsage(ticket),
        requiresSuperUser: true,
    },
];

function isColumnAvailable(column: ColumnDefinition): boolean {
    if (column.requiresSuperUser && !isSuperUser()) return false;
    // noinspection RedundantIfStatementJS
    if (
        column.learningEnvironmentVisibility !== undefined &&
        column.learningEnvironmentVisibility !== isLearningEnvironment()
    )
        return false;
    return true;
}

function isColumnAvailableByKey(columnId: ColumnId): boolean {
    const column = columns.find((c) => c.key == columnId);
    return column !== undefined && isColumnAvailable(column);
}

type ColumnId = string;

function ColumnsButton(props: { value: ReadonlySet<ColumnId>; onChange: (newValue: Set<ColumnId>) => void }) {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleMenuItemClick = (columnId: ColumnId) => {
        console.log("handleMenuItemClick", columnId);
        const newValue = new Set(props.value);
        if (!newValue.has(columnId)) newValue.add(columnId);
        else newValue.delete(columnId);

        props.onChange(newValue);
    };

    const handleToggle = () => {
        console.log("handleToggle");
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        console.log("handleClose", event);
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Button
                    size="medium"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    startIcon={<VisibilityIcon />}
                >
                    Kolonner
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 10000,
                }}
                open={open}
                anchorEl={anchorRef.current}
                placement={"bottom-end"}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem style={{ padding: "20px" }}>
                                    <Masonry
                                        // wrap={"wrap"}
                                        columns={2}
                                        spacing={2}
                                        // direction={"row"}
                                        // spacing={2}
                                    >
                                        {ColumnCategories.map((category) => {
                                            return (
                                                <React.Fragment key={category}>
                                                    <Grid2>
                                                        <Typography variant={"h6"} style={{ paddingBottom: "10px" }}>
                                                            {category}
                                                        </Typography>
                                                        {columns
                                                            .filter(isColumnAvailable)
                                                            .filter((c) => c.category == category)
                                                            .map((column) => {
                                                                const selected = props.value.has(column.key);
                                                                return (
                                                                    <MenuItem
                                                                        key={column.key}
                                                                        selected={selected}
                                                                        onClick={() =>
                                                                            handleMenuItemClick(column.key as ColumnId)
                                                                        }
                                                                        style={{ background: "none" }}
                                                                    >
                                                                        {selected ? (
                                                                            <CheckBoxIcon />
                                                                        ) : (
                                                                            <CheckBoxOutlineBlankIcon />
                                                                        )}
                                                                        {column.label}
                                                                        {column.requiresSuperUser ? (
                                                                            <DeveloperModeIcon />
                                                                        ) : null}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Grid2>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Masonry>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

interface TicketListFilterValue {
    endedReason: ReadonlySet<TicketEndedReason>;
    devicePlatform: DevicePlatform | null;
    appVersionName: ReadonlySet<string>;
    androidSdkInt: ReadonlySet<number>;
    androidModel: ReadonlySet<string>;
    iosSystemVersion: ReadonlySet<string>;
    passengerType: ReadonlySet<PassengerType>;
}

type FilterProps = {
    data: FrontPageQueryQuery["recentTickets"] | null;
    value: TicketListFilterValue;
    onChange: (newValue: Partial<TicketListFilterValue>) => void;
};

function FilterButton(props: FilterProps) {
    if (!isSuperUser()) return null;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        return;
        // if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        //     return;
        // }
        //
        // setOpen(false);
    };

    return (
        <React.Fragment>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" style={{ marginLeft: "20px" }}>
                <Button
                    size="medium"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                    startIcon={<FilterListIcon />}
                >
                    Filter
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1000,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem style={{ padding: "20px" }}>
                                    <Masonry
                                        // wrap={"wrap"}
                                        columns={2}
                                        spacing={2}
                                        // direction={"row"}
                                        // spacing={2}
                                    >
                                        {ColumnCategories.map((category) => {
                                            const filterableColumns = columns
                                                .filter(isColumnAvailable)
                                                .filter((c) => c.category == category)
                                                .filter((c) => c.filterBuilder);

                                            if (filterableColumns.length === 0) return null;

                                            return (
                                                <React.Fragment key={category}>
                                                    <Grid2>
                                                        <Typography variant={"h6"} style={{ paddingBottom: "10px" }}>
                                                            {category}
                                                        </Typography>
                                                        {filterableColumns.map((c) => (
                                                            <React.Fragment key={c.key}>
                                                                {c.filterBuilder!(props)}
                                                            </React.Fragment>
                                                        ))}
                                                    </Grid2>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Masonry>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    );
}

const defaultVisibleColumns = columns.filter((c) => c.defaultVisible || c.requiredVisible).map((c) => c.key);

const requiredVisibleColumns = columns.filter((c) => c.requiredVisible).map((c) => c.key);

function readVisibleColumnsFromLocalStorage(
    visibleColumnsItemKey: string,
    defaultVisibleColumns: ReadonlyArray<string>,
): Set<ColumnId> {
    const result = localStorage.getItem(visibleColumnsItemKey);
    const defaultValue = new Set<ColumnId>(
        [...defaultVisibleColumns, ...requiredVisibleColumns].filter(isColumnAvailableByKey),
    );
    if (!result) return defaultValue;

    try {
        const list = JSON.parse(result);
        return new Set<ColumnId>([...list, ...requiredVisibleColumns].filter(isColumnAvailableByKey));
    } catch (e) {
        console.error("Failed reading visibleRegions from cache", e);
        return defaultValue;
    }
}

function writeVisibleColumsToLocalStorage(visibleColumnsItemKey: string, value: Set<ColumnId>) {
    localStorage.setItem(visibleColumnsItemKey, JSON.stringify([...value]));
}

export function CustomerTicketTable(props: { tickets: ReadonlyArray<TicketRowFragment> }) {
    const visibleColumnsItemKey = "customer_tickets.visibleColumns";

    const [visibleColumns, setVisibleColumns] = useState<Set<ColumnId>>(
        readVisibleColumnsFromLocalStorage(
            visibleColumnsItemKey,
            defaultVisibleColumns.filter((key) => key != "passengerType"),
        ),
    );

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <ColumnsButton
                    value={visibleColumns}
                    onChange={(newValue) => {
                        setVisibleColumns(newValue);
                        writeVisibleColumsToLocalStorage(visibleColumnsItemKey, newValue);
                    }}
                />
            </div>
            <TableContainer component={Paper}>
                <TicketsTable
                    showFooter={true}
                    showCustomerInfo={false}
                    tickets={reverse(sortBy(props.tickets, "createdTime"))}
                    visibleColumns={visibleColumns}
                />
            </TableContainer>
        </div>
    );
}

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage } = props;

    const lastPage = Math.ceil(count / rowsPerPage) - 1;

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton disabled={page === 0} aria-label="first page" component={Link} to={buildLink({ page: 0 })}>
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                disabled={page === 0}
                aria-label="previous page"
                component={Link}
                to={buildLink({ page: page - 1 })}
            >
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                disabled={page >= lastPage}
                aria-label="next page"
                component={Link}
                to={buildLink({ page: page + 1 })}
            >
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                disabled={page >= lastPage}
                aria-label="last page"
                component={Link}
                to={buildLink({ page: lastPage })}
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

export function toAndroidModelInput(id: string): AndroidModelInput {
    if (id.includes(";")) {
        return { manufacturer: id.split(";")[0], model: id.split(";")[1] };
    } else {
        return { manufacturer: id };
    }
}
