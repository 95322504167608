import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { ModalProps } from "../../widgets/dialogs";
import { gql } from "@apollo/client";
import {
    PriceGuaranteeExplanationQueryQuery,
    PriceGuaranteeExplanationQueryQueryVariables,
} from "../../generated/gql/graphql";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { formatTicketId } from "../../util/formatting";
import React from "react";
import { TimestampText } from "../../widgets/date_time";
import { parseTime } from "../../util/date_time";
import { Duration } from "luxon";
import { padStart } from "lodash-es";
import { green } from "@mui/material/colors";
import { formatMoney } from "../NetsTransactionsListPage";
import { sum } from "lodash";
import LoadingModal from "../../widgets/LoadingModal";

const PRICE_GUARANTEE_EXPLANATION_QUERY = gql`
    query PriceGuaranteeExplanationQuery($ticketId: Int!) {
        ticket(id: $ticketId) {
            id
            analysisLocalPrice {
                legs {
                    id
                    startedTime
                    priceGuaranteeExplanation {
                        options {
                            product
                            productName
                            productPrice
                            validConsumption
                            maxPrice
                            zone {
                                description
                                zoneCount
                                zoneA
                                zoneB
                            }
                            eligiblePriceHistory
                        }

                        priceHistory {
                            ticket {
                                id
                                passengerType
                            }

                            leg {
                                id
                                priceGuaranteeProductName
                                description {
                                    journey {
                                        line {
                                            publicCode
                                        }
                                        destination
                                    }

                                    fromStopTime {
                                        platform {
                                            name
                                        }
                                    }
                                    toStopTime {
                                        platform {
                                            name
                                        }
                                    }
                                }
                            }

                            embarkedTime
                            zones {
                                zoneCount
                                fromTariffZone {
                                    id
                                    name
                                    group
                                }
                                toTariffZone {
                                    id
                                    name
                                    group
                                }
                                spec {
                                    zoneCount
                                    zoneA
                                    zoneB
                                    description
                                }
                            }

                            discountedPriceExcludingAddonsIncludingVat
                        }
                    }
                }
            }
        }
    }
`;

function formatZone(
    zone: PriceGuaranteeExplanationQueryQuery["ticket"]["analysisLocalPrice"][0]["legs"][0]["priceGuaranteeExplanation"]["options"][0]["zone"],
) {
    if (zone.zoneCount === 3) return "3 soner";
    if (zone.zoneCount === 2) return ["2 soner", <br />, zone.zoneA, <br />, zone.zoneB];
    if (zone.zoneCount === 1) return ["1 sone", <br />, zone.zoneA];
    return zone.description;
}

export function PriceGuaranteeExplanationModal(props: ModalProps & { ticketId: number; legId: number }) {
    const query = useQuery<PriceGuaranteeExplanationQueryQuery, PriceGuaranteeExplanationQueryQueryVariables>(
        PRICE_GUARANTEE_EXPLANATION_QUERY,
        { variables: { ticketId: props.ticketId } },
    );
    if (query.loading) return <LoadingModal />;
    const ticket = query.data!.ticket;
    const leg = ticket.analysisLocalPrice.flatMap((l) => l.legs).find((l) => l.id == props.legId)!;

    const explenation = leg.priceGuaranteeExplanation;

    return (
        <Dialog open onClose={props.onClose} maxWidth={false}>
            <DialogTitle>Prisgaranti </DialogTitle>
            <DialogContent>
                <div>
                    <Table size={"small"} style={{ width: "max-content" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "100px" }}>Billett</TableCell>
                                <TableCell style={{ width: "200px" }}>Ombord</TableCell>
                                <TableCell>Reise</TableCell>
                                <TableCell>Sone</TableCell>
                                <TableCell align={"right"}>Pris</TableCell>
                                {explenation.options.map((o) => {
                                    return (
                                        <TableCell align={"center"} valign={"top"}>
                                            <span style={{ whiteSpace: "nowrap" }}>{o.productName}</span>
                                            <br />
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "normal",
                                                    lineHeight: "12px",
                                                    height: "12px",
                                                }}
                                            >
                                                {formatZone(o.zone)}
                                            </span>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}>Pris:</TableCell>
                                {explenation.options.map((o) => (
                                    <TableCell align={"right"}>{formatMoney(o.productPrice)}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}>Gyldig forbruk:</TableCell>
                                {explenation.options.map((o) => (
                                    <TableCell align={"right"}>{"- " + formatMoney(o.validConsumption)}</TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={5}>Makspris:</TableCell>
                                {explenation.options.map((o) => (
                                    <TableCell align={"right"}>{"= " + formatMoney(o.maxPrice)}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {explenation.priceHistory.map((h, historyIndex) => {
                                let diff = Duration.fromMillis(
                                    parseTime(leg.startedTime).toMillis() - parseTime(h.embarkedTime).toMillis(),
                                );

                                if (h.discountedPriceExcludingAddonsIncludingVat === 0) return null;

                                const description = h.leg?.description;

                                return (
                                    <TableRow key={h.leg?.id}>
                                        <TableCell style={{ whiteSpace: "no-wrap" }}>
                                            <Link to={"/ticket/" + ticket.id}>{formatTicketId(h.ticket.id)}</Link>
                                        </TableCell>
                                        <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                                            <TimestampText value={h.embarkedTime} />
                                            <br />
                                            <span>{formatAge(diff) + " tidligere"}</span>
                                        </TableCell>
                                        <TableCell>
                                            {description ? (
                                                <>
                                                    {description.journey.line.publicCode}{" "}
                                                    {description.journey.destination}
                                                    <br />
                                                    {description.fromStopTime.platform.name}
                                                    {" – "}
                                                    {description.toStopTime.platform.name}
                                                </>
                                            ) : null}
                                        </TableCell>
                                        <TableCell>{h.zones.spec.description}</TableCell>
                                        <TableCell align={"right"} style={{ whiteSpace: "nowrap" }}>
                                            {formatMoney(h.discountedPriceExcludingAddonsIncludingVat)}
                                            <br />
                                            {h.leg?.priceGuaranteeProductName}
                                        </TableCell>
                                        {explenation.options.map((o) => {
                                            const eligible = o.eligiblePriceHistory.indexOf(historyIndex) !== -1;

                                            if (!eligible) return <TableCell />;
                                            const accumulatedPriceHistory = sum(
                                                o.eligiblePriceHistory
                                                    .filter((e) => e >= historyIndex)
                                                    .map(
                                                        (hIndex) =>
                                                            explenation.priceHistory[hIndex]
                                                                .discountedPriceExcludingAddonsIncludingVat,
                                                    ),
                                            );

                                            return (
                                                <TableCell align={"right"} style={{ backgroundColor: green[200] }}>
                                                    {formatMoney(accumulatedPriceHistory)}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={props.onClose}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function formatAge(diff: Duration) {
    diff = diff.shiftTo("days", "hours", "minutes", "seconds");

    diff = Duration.fromObject({
        days: diff.days,
        hours: diff.hours,
        minutes: diff.minutes,
    });

    return (
        diff.days.toString() +
        " dager + " +
        padStart(diff.hours.toString(), 2, "0") +
        ":" +
        padStart(diff.minutes.toString(), 2, "0")
    );
}
