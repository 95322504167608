import React, { useMemo } from "react";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { AndroidManufacturer, DevicePlatform } from "../../generated/gql/graphql";
import {
    Autocomplete,
    darken,
    FormControl,
    FormControlLabel,
    InputLabel,
    lighten,
    MenuItem,
    OutlinedInput,
    Radio,
    RadioGroup,
    Select,
    styled,
    TextField,
    Theme,
    useTheme,
} from "@mui/material";
import { capitalize } from "lodash";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
            width: 300,
        },
    },
};

export interface MultiSelectFilterProps<T> {
    id: string;
    label: React.ReactNode;
    options: ReadonlyArray<T>;
    value: ReadonlyArray<T>;
    onChange: (newValue: ReadonlyArray<T>) => void;
}

function getStyles(option: string, value: ReadonlyArray<string>, theme: Theme) {
    return {
        fontWeight: value.includes(option) ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
    };
}

export function MultiSelectFilter<T extends string>(props: MultiSelectFilterProps<T>) {
    const theme = useTheme();
    console.log("filter", props);

    function handleChange(event: SelectChangeEvent<ReadonlyArray<T>>) {
        const v = event.target.value;
        console.log("onChange", v);
        if (typeof v === "string") props.onChange(v.split(",") as unknown as ReadonlyArray<T>);
        else props.onChange(v);
    }

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id={props.id + "-label"}>{props.label}</InputLabel>
            <Select<ReadonlyArray<T>>
                labelId={props.id + "-label"}
                id={props.id}
                multiple
                value={props.value}
                onChange={handleChange}
                input={<OutlinedInput label={props.label} />}
                renderValue={(selected) => [...selected].join(", ")}
                MenuProps={MenuProps}
            >
                {props.options.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, props.value, theme)}>
                        {name}
                        {/*<Checkbox checked={props.value.includes(name)} />*/}
                        {/*<ListItemText primary={name} />*/}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function DevicePlatformFilter(props: {
    value: DevicePlatform | null;
    onChange: (newValue: DevicePlatform | null) => void;
}) {
    return (
        <FormControl>
            <RadioGroup
                row
                // aria-labelledby="device-platform-filter"
                name="device-platform"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value as DevicePlatform | null)}
            >
                <FormControlLabel value={null} control={<Radio />} label="iOS og Android" />
                <FormControlLabel value={DevicePlatform.Ios} control={<Radio />} label="iOS" />
                <FormControlLabel value={DevicePlatform.Android} control={<Radio />} label="Android" />
            </RadioGroup>
        </FormControl>
    );
}

type AndroidModelOption = {
    id: string;
    title: string;
    manufacturer: string;
};

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
    ...theme.applyStyles("dark", {
        backgroundColor: darken(theme.palette.primary.main, 0.8),
    }),
}));

const GroupItems = styled("ul")({
    padding: 0,
});

export function AndroidModelsFilter(props: {
    options: ReadonlyArray<AndroidManufacturer>;
    value: ReadonlySet<string>;
    onChange: (newValue: ReadonlySet<string>) => void;
}) {
    console.log("AndroidModelsFilter", props);
    const options = useMemo<ReadonlyArray<AndroidModelOption>>(() => {
        console.log("updating options");
        return props.options.flatMap<AndroidModelOption>((m) => {
            return [
                {
                    id: m.manufacturer,
                    title: capitalize(m.manufacturer),
                    manufacturer: capitalize(m.manufacturer),
                },
                ...m.models.map((model) => {
                    return {
                        id: m.manufacturer + ";" + model,
                        title: capitalize(m.manufacturer) + " — " + model,
                        manufacturer: capitalize(m.manufacturer),
                    };
                }),
            ];
        });
    }, [props.options]);
    const value = useMemo<AndroidModelOption[]>(() => {
        console.log("updating value");
        return options.filter((o) => props.value.has(o.id));
    }, [options, [...props.value.values()].join(",")]);

    console.log("value", value);

    return (
        <FormControl fullWidth>
            <Autocomplete<AndroidModelOption, true>
                multiple
                // id="android-model-filter"
                options={options}
                // getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.title}
                groupBy={(option) => option.manufacturer}
                disablePortal
                value={value}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
                onChange={(_, newValue) => {
                    console.log("onChange", newValue);
                    if (newValue) {
                        let filteredOptions = [...newValue];

                        for (const option of newValue) {
                            if (!option.id.includes(";")) {
                                filteredOptions = filteredOptions.filter((v) => !v.id.startsWith(option.id + ";"));
                            }
                        }

                        props.onChange(new Set(filteredOptions.map((v) => v.id)));
                    } else {
                        props.onChange(new Set());
                    }
                }}
                renderInput={(params) => <TextField {...params} label="Android modell" />}
            />
        </FormControl>
    );
}
